<template>
    <div>
        <div class="">
            <div class="text-center">
                <h2 class="text-uppercase lined lined-center">Unsere <span class="text-primary">Vortrags- und
                        Schulungsangebote</span>
                </h2>
                <p class="text-muted mb-5">Weil wir unser Wissen gerne teilen</p>
            </div>
            <div class="row align-items-stretch gx-0">
                <div class="col-lg-6">
                    <div class="p-2 shadow mt-2"><img class="img-fluid" src="@/assets/img/slider/vortrag.jpg" alt=""></div>

                </div>
                <div class="col-lg-6 mt-5 p-5">
                    <div class="p-2 bg-primary">
                        <div class="border border-white border-2 p-lg-5 p-4 d-flex align-items-center">
                            <div class="ps-3">
                                <h4 class="text-white mb-1">kleines Budget - große Leistung</h4>
                                <div class="row gy-4 pt-4 text-white">
                                    <p>Als starker Partner im Gesundheitswesen arbeiten
                                        wir selbstverständlich in enger Kooperation mit den
                                        Gesundheitskassen zusammen. Das bedeutet
                                        wiederum für Sie, dass wir den
                                        Großteil unserer Angebote für Sie *kostenfrei zur
                                        Verfügung stellen können. Gerne beraten wir Sie,
                                        wenn es um die Finanzierung Ihrer Wunschleistung
                                        für Ihre Bewohner*innen und Mitarbeiter*innen geht.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="card border-0">
                    <div class="card-body py-5 border-top border-2 border-primary bg-light">
                        <div class="row gy-4">
                            <div class="col-lg-6 border-end">
                                <div class="px-4">
                                    <h3 class="h5">Schulungen</h3>
                                    <ul class="list-unstyled text-primary text-sm">
                                        <hr />
                                        <li class="">
                                            Mobilisation bei Menschen mit teil oder vollständiger Immobilität...
                                        </li>
                                        <li class="mb-2 text-muted">
                                            im Sinne des Expertenstandards Mobilitätsförderung
                                        </li>
                                        <hr />
                                        <li class="">
                                            Mobilitätsförderung bei Menschen mit Demenz...
                                        </li>
                                        <li class="mb-2 text-muted">
                                            im Sinne des Expertenstandards Mobilitätsförderung
                                        </li>
                                        <hr />
                                        <li class="">
                                            Bobath Transfer in der Pflege...
                                        </li>
                                        <li class="mb-2 text-muted">
                                            unter Berücksichtigung kinästhetischer Prinzipien
                                        </li>
                                        <hr />
                                        <li class="">
                                            Sturzprävention...
                                        </li>
                                        <li class="mb-2 text-muted">
                                            nach dem Ulmer Modell
                                        </li>
                                        <hr />
                                        <li class="">
                                            Positionierung...
                                        </li>
                                        <li class="mb-2 text-muted">
                                            im Sinne des Expertenstandards Dekubitusprophylaxe
                                        </li>
                                        <hr />
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="px-4">
                                    <h3 class="h5">Vorträge und Betriebliche Gesundheitsförderung</h3>
                                    <ul class="list-unstyled text-primary text-sm">
                                        <hr />
                                        <li class="">
                                            Ergonomie am Arbeitsplatz
                                        </li>
                                        <hr />
                                        <li class="">
                                            Schmerzen verstehen
                                        </li>
                                        <hr />
                                        <li class="">
                                            Stressmanagement im Berufsalltag
                                        </li>
                                        <hr />
                                        <li class="">
                                            Rücken Qi Gong
                                        </li>
                                        <hr />
                                        <li class="">
                                            Rückenschule
                                        </li>
                                        <hr />
                                        <li class="">
                                            FUNctionalFit
                                        </li>
                                        <hr />
                                        <li class="">
                                            Ganzheitlich stressfrei
                                        </li>
                                        <hr />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6 justify-content-md-center">
                        <div class="card border-0 shadow-sm h-100 bg-light">
                            <div class="card-body p-5 border-top border-primary border-2">
                                <h2 class="lined"><span class="text-primary">Interesse</span> geweckt?</h2>
                                <p class="text-muted mb-4">Jetzt Anfrage senden</p>
                                <div class="row gy-4">
                                    <div class="col-lg-12">
                                        <input class="form-control" v-model="name" type="text" name="name"
                                            placeholder="Name" :disabled="success">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="form-control" v-model="email" type="text" name="email"
                                            placeholder="E-Mail" :disabled="success">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="form-control" v-model="phone" type="tel" name="phone"
                                            placeholder="Telefon" :disabled="success">
                                    </div>
                                    <div class="col-lg-12">
                                        <input class="form-control" v-model="company" type="text" name="company"
                                            placeholder="Einrichtung" :disabled="success">
                                    </div>
                                    <div class="col-lg-12">
                                        <input class="form-control" v-model="address" type="text" name="address"
                                            placeholder="PLZ / Ort" :disabled="success">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea class="form-control" v-model="message" name="message" rows="5"
                                            placeholder="Nachricht" :disabled="success"></textarea>
                                        <small><span class="text-muted">(Beschreiben Sie kurz, welche Vortragsthemen für sie
                                                relevant sind.)</span></small>
                                    </div>
                                    <div class="col-lg-12">
                                        <button v-if="!success" :disabled="!enableSubmit" class="btn btn-primary w-100"
                                            v-on:click="sendApplication"><i class="fas fa-envelope"></i> Senden</button>
                                        <p class="text-success" v-if="success">Vielen Dank für Deine Nachricht! Wir melden
                                            uns innerhalb der
                                            nächsten zwei Tage bei dir.</p>
                                        <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers
                                            gerne nochmal oder
                                            ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{
                                                $t('frame.phone') }}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="row mt-5">
                    <div class="col-md-3">
                        <iframe class="" src='https://www.youtube.com/embed/6wd7B7oU7eE' frameborder='0'
                            allowfullscreen></iframe>
                    </div>
                    <div class="col-md-3">
                        <iframe class="" src='https://www.youtube.com/embed/zP-fYqoOzPE' frameborder='0'
                            allowfullscreen></iframe>
                    </div>
                    <div class="col-md-3">
                        <iframe class="" src='https://www.youtube.com/embed/zLUBDUf4qbs' frameborder='0'
                            allowfullscreen></iframe>
                    </div>
                    <div class="col-md-3">
                        <iframe class="" src='https://www.youtube.com/embed/hhIqB2f2Dn8' frameborder='0'
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <p class="text-sm p-0 m-0">
                    * Im Bezug einer Kooperationsvereinbarung, die unabhängig von unserem
                    Therapiezentrum mit einer Gesundheitskasse statt ndet
                    können oben erwähnte Angebote (Schulungen, Vorträge, betriebliche
                    Gesundheitsförderung) bezuschusst oder teils komplett Kosten
                    technisch übernommen werden. Hierzu stellt unser Therapiezentrum auf Wunsch den
                    Kontakt zu einer Gesundheitskasse für Ihre Einrichtung her.
                </p>

            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../services/axiosConfig';

export default {
    name: '',
    data() {
        return {
            success: false,
            error: false,
            name: '',
            company: '',
            address: '',
            email: '',
            phone: '',
            message: ''
        };
    },
    computed: {
        enableSubmit() {
            return true;
        }
    },
    methods: {
        sendApplication() {
            this.success = false;
            this.error = false;
            axios.post('vortrag/', {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message,
                company: this.company,
                address: this.address
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else {
                    this.error = true;
                }
            }).catch(() => {
                this.error = true;
            });
        }
    }
};
</script>

<style scoped>
.slider-background {
    width: 100%;
    object-fit: cover;
}

</style>
